import React from "react";
import { graphql } from "gatsby";
import GatsbyImage from "gatsby-image";
import PageWrapper from "components/PageWrapper";
import RichText from "components/RichText";
import log from "utils/log";
import * as siteTheme from "theme";
import * as heroClasses from "./hero.module.css";

export const query = graphql`
  query {
    descriptionImage: contentfulAsset(
      contentful_id: { eq: "4MO4JDc5UBW9bUV9oR5DaR" }
    ) {
      id
      fixed {
        src
      }
    }
    rescue: allContentfulText(
      filter: { contentful_id: { eq: "1LuIjLTtLbsEhBPlA1IdPS" } }
    ) {
      nodes {
        id
        text {
          raw
        }
      }
    }
    vetKorea: allContentfulText(
      filter: { contentful_id: { eq: "6w51RozbEe7yP2b5JKNg7a" } }
    ) {
      nodes {
        id
        text {
          raw
        }
      }
    }
    vetChina: allContentfulText(
      filter: { contentful_id: { eq: "IY2N3SgvpCyQKZtftnt6e" } }
    ) {
      nodes {
        id
        text {
          raw
        }
      }
    }
    fostersKorea: allContentfulText(
      filter: { contentful_id: { eq: "OznQ559JWhCG8ffWNv3mT" } }
    ) {
      nodes {
        id
        text {
          raw
        }
      }
    }
    havenChina: allContentfulText(
      filter: { contentful_id: { eq: "6CrZ000ojRnMFkhwLo6452" } }
    ) {
      nodes {
        id
        text {
          raw
        }
      }
    }
    freedomFlight: allContentfulText(
      filter: { contentful_id: { eq: "3lVlgdaeHdz6z32yex2l2i" } }
    ) {
      nodes {
        id
        text {
          raw
        }
      }
    }
    transportation: allContentfulText(
      filter: { contentful_id: { eq: "3V8kReqdn72dhTaBDFGXrT" } }
    ) {
      nodes {
        id
        text {
          raw
        }
      }
    }
    rescueImages: contentfulGallery(
      contentful_id: { eq: "3ttG8NNPbta3sgklH5A1KA" }
    ) {
      id
      galleryItems {
        id
        fluid(maxHeight: 200, maxWidth: 480, resizingBehavior: THUMB) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    transportationImages: contentfulGallery(
      contentful_id: { eq: "4qdzoJ81AjRZ8cyGiZnFdM" }
    ) {
      id
      galleryItems {
        id
        fluid(maxHeight: 480, maxWidth: 480, resizingBehavior: THUMB) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    safeHavenImages: contentfulGallery(
      contentful_id: { eq: "2enrV9x1W84tXxK6LZrMUI" }
    ) {
      id
      galleryItems {
        id
        fluid(
          maxHeight: 480
          maxWidth: 480
          resizingBehavior: FILL
          cropFocus: CENTER
        ) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    vetImages: contentfulGallery(
      contentful_id: { eq: "14PmuYVZxa1gZKjdH8wWhH" }
    ) {
      id
      galleryItems {
        id
        fluid(
          maxHeight: 480
          maxWidth: 480
          resizingBehavior: FILL
          cropFocus: CENTER
        ) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    flightImages: contentfulGallery(
      contentful_id: { eq: "22CBTxdX1P3JidZ21GbAYk" }
    ) {
      id
      galleryItems {
        id
        fluid(
          maxHeight: 480
          maxWidth: 480
          resizingBehavior: FILL
          cropFocus: CENTER
        ) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;

function present(data) {
  const seo = {
    title: "Home",
    image: data?.descriptionImage?.fixed?.src,
    description:
      "Nana's Haven in St. Petersburg, FL offers support and services for neglected and abused animals. Learn more about us today.",
  };

  const presentation = {
    seo,
    rescue: data?.rescue?.nodes[0]?.text,
    vetKorea: data?.vetKorea?.nodes[0]?.text,
    vetChina: data?.vetChina?.nodes[0]?.text,
    fostersKorea: data?.fostersKorea?.nodes[0]?.text,
    havenChina: data?.havenChina?.nodes[0]?.text,
    freedomFlight: data?.freedomFlight?.nodes[0]?.text,
    transportation: data?.transportation?.nodes[0]?.text,
    rescueImages: data?.rescueImages?.galleryItems,
    transportationImages: data?.transportationImages?.galleryItems,
    vetImages: data?.vetImages?.galleryItems,
    safeHavenImages: data?.safeHavenImages?.galleryItems,
    flightImages: data?.flightImages?.galleryItems,
  };

  log("[PAGE] presentation", presentation);

  return presentation;
}

export default function Page({ data }) {
  const {
    rescue,
    transportation,
    vetKorea,
    vetChina,
    fostersKorea,
    havenChina,
    freedomFlight,
    rescueImages,
    transportationImages,
    vetImages,
    safeHavenImages,
    flightImages,
    seo,
  } = present(data);

  return (
    <PageWrapper seo={seo}>
      {/* Hero ---> */}
      <div className="container mx-auto px-8 md:px-16">
        <div className={heroClasses.heroLayout}>
          <div className={heroClasses.heroText}>
            <div className={heroClasses.heroTextLayout}>
              <h1 className={heroClasses.heroTitle}>Our Process</h1>
              <div className={heroClasses.heroContent}>
                <RichText
                  json={rescue}
                  theme={{
                    title: {
                      color: siteTheme.heading.primary,
                      fontFamily: siteTheme.font.primary,
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className={heroClasses.heroImage}>
            {rescueImages.map((image) => (
              <GatsbyImage
                key={image.id}
                fluid={image.fluid}
                style={{ width: "100%" }}
              />
            ))}
          </div>
        </div>
      </div>
      {/* <---- Hero */}

      <div className="bg-gray-100 py-32">
        <div className="container mx-auto px-8 md:px-16">
          <div className="md:grid md:gap-8 md:grid-cols-12 mb-12">
            <div className="md:col-span-8 lg:col-span-6 xl:col-span-4">
              <RichText
                json={transportation}
                theme={{
                  title: {
                    color: siteTheme.heading.primary,
                    fontFamily: siteTheme.font.primary,
                  },
                }}
              />
            </div>
          </div>
          <div className="grid gap-8 grid-cols-3">
            {transportationImages.map((image) => (
              <div key={image.id} className="col-span-1">
                <GatsbyImage fluid={image.fluid} />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="container mx-auto px-8 md:px-16 py-32">
        <div className="grid gap-8 md:grid-cols-12 mb-8">
          <div className="md:col-span-6 lg:col-start-2 lg:col-span-5">
            <RichText
              json={vetChina}
              theme={{
                title: {
                  color: siteTheme.heading.primary,
                  fontFamily: siteTheme.font.primary,
                },
              }}
            />
          </div>
          <div className="md:col-span-6 lg:col-span-5">
            <RichText
              json={vetKorea}
              theme={{
                title: {
                  color: siteTheme.heading.primary,
                  fontFamily: siteTheme.font.primary,
                },
              }}
            />
          </div>
        </div>
        <div className="grid gap-8 grid-cols-2 md:grid-cols-4 my-32">
          {vetImages.map((image) => (
            <div key={image.id} className="col-span-1">
              <GatsbyImage fluid={image.fluid} />
            </div>
          ))}
        </div>
        <div className="grid gap-8 md:grid-cols-12">
          <div className="md:col-span-6 lg:col-start-2 lg:col-span-5">
            <RichText
              json={havenChina}
              theme={{
                title: {
                  color: siteTheme.heading.primary,
                  fontFamily: siteTheme.font.primary,
                },
              }}
            />
          </div>
          <div className="md:col-span-6 lg:col-span-5">
            <RichText
              json={fostersKorea}
              theme={{
                title: {
                  color: siteTheme.heading.primary,
                  fontFamily: siteTheme.font.primary,
                },
              }}
            />
          </div>
        </div>
        <div className="grid gap-8 grid-cols-2 md:grid-cols-4 mt-32">
          {safeHavenImages.map((image) => (
            <div key={image.id} className="col-span-1">
              <GatsbyImage fluid={image.fluid} />
            </div>
          ))}
        </div>
      </div>

      <div className="bg-gray-200 py-32">
        <div className="container mx-auto px-8 md:px-16">
          <div className="grid gap-8 md:grid-cols-12 mb-8">
            <div className="md:col-span-8 lg:col-span-6 xl:col-span-4">
              <RichText
                json={freedomFlight}
                theme={{
                  title: {
                    color: siteTheme.heading.primary,
                    fontFamily: siteTheme.font.primary,
                  },
                }}
              />
            </div>
          </div>
          <div className="grid gap-8 grid-cols-2">
            {flightImages.map((image) => (
              <div key={image.id} className="col-span-1">
                <GatsbyImage fluid={image.fluid} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}
